export default {
	state: {
		tagsList: []
	},
	getters: {
		tagsList(state) {
			return state.tagsList;
		},
	},
	mutations: {
		// 设置存储标签列表
		setTagsList(state, data) {
			state.tagsList = data;
		},
		// 删除标签
		deleteTag(state, data) {
			state.tagsList.splice(data.index, 1);
		},
		// 关闭所有标签
		closeAllTags(state) {
			state.tagsList = []
		},
		// 关闭其他标签
		closeOtherTags(state, data) {
		    state.tagsList = data;
		},
	},
	actions: {

	}
}
