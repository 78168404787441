<template>
	<div>
		<el-dialog class="app-pick-link" :title="title ? title : '选择链接'" :visible.sync="dialogFormVisible"
			:close-on-click-modal="false" append-to-body>
			<el-tabs v-model="currentTab" @tab-click="hanleClick">
				<el-tab-pane label="文章" name="article">
					<div class="d-flex align-items-center m-1">
						<el-input style="width: 200px" size="small" v-model="articleSearch.keyword" placeholder="请输入搜索内容" clearable
							@clear="toArticleSearch" @keyup.enter.native="toArticleSearch">
						</el-input>
						<el-button class="ml-1" @click="toArticleSearch" type="primary" size="mini" icon="el-icon-search"></el-button>
					</div>
					<el-table :data="articleList.data" style="width: 100%">
						<el-table-column align="center" width="120" prop="article_id" label="文章ID"></el-table-column>
						<el-table-column align="center" label="文章标题">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.article_title}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="封面" width="90">
							<template slot-scope="scope">
								<viewer>
									<img style="width: 45px;height: 45px;" :src="scope.row.image">
								</viewer>
							</template>
						</el-table-column>
						<el-table-column align="center" label="文章分类">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.category.name}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" label="文章排序">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.article_sort}}</span>
							</template>
						</el-table-column>
						<el-table-column align="center" prop="article_status" label="是否显示">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.article_status" :active-value="10"
									@change="handleStatus(scope.row)" :inactive-value="20"></el-switch>
							</template>
						</el-table-column>
						<el-table-column align="center" label="添加时间">
							<template slot-scope="scope">
								<span style="font-weight: 800;">{{scope.row.create_time}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button plain size="mini" type="primary" @click="handleActivity(scope.row)">选择
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div slot="footer" class="dialog-footer">
						<el-pagination v-if="articleList" background @current-change="articlePageChange"
							layout="prev, pager, next" :page-size="20" :total="articleList.total_count">
						</el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<div @click="dialogFormVisible = !dialogFormVisible" style="display: inline-block">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,
			params: Object,
		},
		data() {
			return {
				dialogFormVisible: false,
				currentTab: 'article',
				articleList: [],
				articleSearch: {
					s: 'store/content.article/index',
					keyword: "",
					page: 1,
				},
				page: {

				}
			}
		},
		created() {
			this.searchArticle()
		},
		methods: {
			toArticleSearch() {
				this.articleSearch.page = 1;
				this.searchArticle()
			},
			hanleClick() {},
			searchArticle() {
				this.axios({
					token: true,
					params: this.articleSearch,
					method: 'get',
				}).then(res => {
					this.articleList = res.data.data
				}).catch(err => {})
			},
			// 分页
			articlePageChange(page) {
				this.searchArticle();
			},
			handleActivity(article) {
				this.$emit('selected', '/pages/articleDetail/articleDetail?article_id=' + article.article_id, this.params);
				this.dialogFormVisible = !this.dialogFormVisible
			},
		},
	}
</script>

<style>
	.app-pick-link .el-checkbox+.el-checkbox {
		margin-left: 0;
	}

	.app-pick-link .checkbox-div-box {
		height: 350px;
		overflow: auto;
	}

	.app-pick-link .edit-img {
		width: 18px;
		height: 18px;
		display: inline-block;
		margin-left: 10px;
		cursor: pointer;
	}

	.app-pick-link .el-dialog {
		width: 800px;
	}

	.app-pick-link .el-checkbox {
		margin-right: 0;
		height: 32px;
	}

	.app-pick-link .el-checkbox__input {
		margin-top: 4px;
	}
</style>
